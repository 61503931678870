import React, { FC, useState } from "react";

import { CallToAction, Image, Link } from "@src/components/ui";
import {
	IComponentCallToAction,
	IComponentNavigationPageLink,
} from "@src/types/page-components.types";
import { ReactComponent as GreyAccordionArrowDown } from "@assets/icons/grey-accordion-arrow-down.svg";
import { ReactComponent as GrayArrowRightSmall2 } from "@assets/icons/gray-arrow-right-small-2.svg";

type Props = {
	pageLinks: IComponentNavigationPageLink[];
	callToAction: IComponentCallToAction;
	isOpen: boolean;
	onClose: () => void;
};

const Drawer: FC<Props> = (props) => {
	const { pageLinks, callToAction, isOpen, onClose } = props;

	const [activeAccordion, setActiveAccordion] = useState<string | null>(null);

	const handleActiveAccordion = (key: string): void => {
		if (activeAccordion === key) return setActiveAccordion(null);
		setActiveAccordion(key);
	};

	const handleClose = (): void => {
		onClose();
		setActiveAccordion(null);
	};

	return (
		<div
			className={`lg:hidden z-40 fixed top-[58px] left-0 w-screen h-screen pb-[60px] bg-white border-t-[0.5px] border-[#D6D7E6] transition-transform duration-200 ${isOpen ? "translate-y-0" : "-translate-y-full"}`}
		>
			<div className="flex flex-col justify-between h-full overflow-y-auto">
				<ul className="my-8 mx-4">
					{pageLinks.map((pageLink) => {
						const { _key, link, subLinks } = pageLink;

						const isActive = activeAccordion === _key;
						const hasSubLinks = Boolean(subLinks.length);
						const accordionHeightClass = `h-[${subLinks.length * 48}px]`;

						return (
							<li
								key={_key}
								className="border-b-[0.5px] border-[#D6D7E6] last:border-none cursor-pointer"
								onClick={() => handleActiveAccordion(_key)}
							>
								<div className="flex items-center justify-between">
									<Link target={link.target} onClick={handleClose} className="flex-1">
										<p className="py-4 text-h4-mobile">{link.label}</p>
									</Link>
									{hasSubLinks ? (
										<div
											className={`transition-transform duration-200 ${isActive ? "-rotate-180" : "rotate-0"}`}
										>
											<GreyAccordionArrowDown fill="#C3C4D8" />
										</div>
									) : (
										<GrayArrowRightSmall2 />
									)}
								</div>
								{hasSubLinks && (
									<ul
										className={`transition-all duration-200 overflow-hidden ${isActive ? `${accordionHeightClass} mt-8 opacity-100` : "h-0 mt-0 opacity-0 pointer-events-none"} `}
									>
										{subLinks.map((subLink) => {
											const { _key, label, target, icon } = subLink;

											return (
												<li key={_key} className="mb-6">
													<Link target={target} onClick={handleClose}>
														<div className="flex items-center">
															{icon && <Image CMSImage={icon} alt={label} className="mr-3" />}
															<p>{label}</p>
														</div>
													</Link>
												</li>
											);
										})}
									</ul>
								)}
							</li>
						);
					})}
				</ul>
				<div className="py-2 border-t-[0.5px] border-[#D6D7E6]">
					<CallToAction
						CMSCallToAction={callToAction}
						className="flex items-center justify-center gap-2 mx-4 py-5 transition-colors duration-200 bg-primary hover:bg-[#000096] rounded-full text-white text-center font-medium"
						onClick={handleClose}
					/>
				</div>
			</div>
		</div>
	);
};

export default Drawer;
