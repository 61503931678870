import React, { FC } from "react";

import { PageLink, SocialMedia } from "./components";
import {
	IComponentExternalCallToAction,
	IComponentLink,
	IComponentLinkWithSubLinks,
	IWithKey,
} from "@src/types/page-components.types";

type Props = {
	content: {
		pageLinks: (IComponentLinkWithSubLinks & IWithKey)[];
		socialMediaLink: IComponentLink;
		socialMedia: (IComponentExternalCallToAction & IWithKey)[];
	};
};

const PageLinks: FC<Props> = (props) => {
	const { content } = props;
	const { pageLinks, socialMediaLink, socialMedia } = content;

	const linksWithSubLink = [] as (IComponentLinkWithSubLinks & IWithKey)[];
	const linksWithNoSubLink = [] as (IComponentLinkWithSubLinks & IWithKey)[];

	pageLinks.forEach((pageLink) => {
		if (pageLink.subLinks.length > 0) {
			linksWithSubLink.push(pageLink);
		} else {
			linksWithNoSubLink.push(pageLink);
		}
	});

	return (
		<div className="lg:flex lg:justify-between lg:w-2/3">
			<div className="flex lg:flex-col justify-between lg:w-2/5 lg:mr-8 mb-10 lg:mb-0">
				{Boolean(linksWithSubLink[0]) && (
					<div>
						<PageLink content={linksWithSubLink[0]} />
					</div>
				)}

				{Boolean(linksWithNoSubLink.length) && (
					<div className="lg:mb-[-1rem]">
						{linksWithNoSubLink.map((pageLink) => (
							<PageLink key={pageLink._key} content={pageLink} />
						))}
					</div>
				)}
			</div>

			<div className="flex-1 flex justify-between">
				{Boolean(linksWithSubLink.length) &&
					linksWithSubLink.map((pageLink, index) => {
						if (index === 0) return null;

						return (
							<div key={pageLink._key}>
								<PageLink content={pageLink} />
							</div>
						);
					})}

				<SocialMedia content={{ link: socialMediaLink, socialMedia }} />
			</div>
		</div>
	);
};

export default PageLinks;
