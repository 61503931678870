import { useEffect, useState } from "react";

import { IUseCookieBannerReturn } from "./useCookieBanner.types";
import { COOKIE_CONSENT_KEY, COOKIE_CONSENT_VALUES } from "../../cookie-banner.constants";

const useCookieBanner = (): IUseCookieBannerReturn => {
	const [isCookieBannerOpen, setIsCookieBannerOpen] = useState<boolean>(false);
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	const handleOpenDialog = (): void => setIsDialogOpen(true);
	const handleCloseDialog = (): void => setIsDialogOpen(false);

	const handleDeny = (): void => {
		localStorage.setItem(COOKIE_CONSENT_KEY, COOKIE_CONSENT_VALUES.DENY);
		setIsCookieBannerOpen(false);
		setIsDialogOpen(false);
	};

	const handleAccept = (): void => {
		localStorage.setItem(COOKIE_CONSENT_KEY, COOKIE_CONSENT_VALUES.ACCEPT);
		setIsCookieBannerOpen(false);
		setIsDialogOpen(false);
	};

	useEffect(() => {
		const cookieConsent = localStorage.getItem(COOKIE_CONSENT_KEY);

		if (cookieConsent === null) setIsCookieBannerOpen(true);
	}, []);

	return {
		state: {
			isCookieBannerOpen,
			isDialogOpen,
		},
		handlers: {
			handleOpenDialog,
			handleCloseDialog,
			handleDeny,
			handleAccept,
		},
	};
};

export default useCookieBanner;
