import React, { FC } from "react";
import { GatsbyImage, GatsbyImageProps, getImage } from "gatsby-plugin-image";

import { IImage } from "@src/types/page-components.types";

type TGatsbyImageProps = Omit<GatsbyImageProps, "image">;

type Props = {
	CMSImage: IImage;
} & TGatsbyImageProps;

const Image: FC<Props> = (props) => {
	const { CMSImage, ...rest } = props;

	const image = getImage(CMSImage?.asset);

	if (!image) return null;

	return <GatsbyImage {...rest} image={image} />;
};

export default Image;
