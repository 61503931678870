import React, { FC, HTMLProps, InputHTMLAttributes } from "react";

type Props = {
	error?: boolean;
	errorText?: string;
	rootProps?: HTMLProps<HTMLDivElement>;
	inputProps?: InputHTMLAttributes<HTMLInputElement>;
};

const Input: FC<Props> = (props) => {
	const { error = false, errorText = "", rootProps, inputProps } = props;

	return (
		<div {...rootProps}>
			<span className="inline-block w-full p-[1px] bg-gradient-to-t to-30% from-[#8F90A7] to-[#C2C3D0] hover:to-[#8F90A7] focus-within:to-[#8F90A7]">
				<input
					{...inputProps}
					className={`${inputProps?.className} ${error ? "text-[#E41D3E]" : "text-black"} w-full p-5 bg-[#CFD0E3] outline-none border-none text-body-mobile md:text-body-mobile placeholder:text-body-mobile md:placeholder:text-body-desktop placeholder:text-gray-02`}
				/>
			</span>
			{error && (
				<p className="mt-2 text-[0.75rem] leading-[0.875rem] tracking-[-0.5%] text-[#E41D3E]">
					{errorText}
				</p>
			)}
		</div>
	);
};

export default Input;
