import React from "react";
import { GatsbyBrowser } from "gatsby";

import Layout from "./src/layout/layout";

const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element, props }) => {
	return <Layout {...props}>{element}</Layout>;
};

export default wrapPageElement;
