import React, { FC, ImgHTMLAttributes } from "react";
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";

import { ILocalImage } from "@src/types/page-components.types";

type TGatsbyImageProps = Omit<GatsbyImageProps, "image">;

type Props = {
	CMSImage: ILocalImage;
} & TGatsbyImageProps;

const ImageV2: FC<Props> = (props) => {
	const { CMSImage, ...rest } = props;

	const { localFile, width, height } = CMSImage.asset;

	const publicURL = localFile?.publicURL;
	const extension = localFile?.extension;

	if (publicURL && extension === "svg") {
		return (
			<img
				src={publicURL}
				width={width}
				height={height}
				loading="lazy"
				{...(rest as ImgHTMLAttributes<HTMLImageElement>)}
			/>
		);
	}

	const gatsbyImageData = localFile?.childImageSharp?.gatsbyImageData;

	if (!gatsbyImageData) return null;

	return <GatsbyImage {...rest} image={gatsbyImageData} />;
};

export default ImageV2;
