import React, { FC } from "react";

import { Link } from "@src/components/ui";
import { IComponentLink } from "@src/types/page-components.types";

type Props = {
	content: {
		copyright: string;
		link: IComponentLink;
	};
};

const PrivacyPolicy: FC<Props> = (props) => {
	const { content } = props;

	const { copyright, link } = content;
	const { target, label } = link;

	return (
		<div className="mt-14 lg:mt-20 pt-6 px-4 border-t-[1px] border-[#323337]">
			<p className="text-[0.75rem] leading-5 font-light text-[#4B5059] text-center">
				{copyright} <br />
				<Link target={target}>
					<span className="underline">{label}</span>
				</Link>
			</p>
		</div>
	);
};

export default PrivacyPolicy;
