import React, { FC } from "react";

type Props = {
	isOpen: boolean;
	onClick: () => void;
};

const BurgerMenu: FC<Props> = (props) => {
	const { isOpen, onClick } = props;

	const cn = "block h-0.5 w-6 rounded-sm transition-all ease-out bg-[#343330]";

	return (
		<button
			aria-label="Menu"
			onClick={onClick}
			className="lg:hidden flex flex-col justify-center items-center"
		>
			<span
				className={`${cn} duration-200 ${isOpen ? "rotate-45 translate-y-1" : "-translate-y-0.5"}`}
			/>

			<span className={`${cn} duration-100 my-0.5 ${isOpen ? "opacity-0" : "opacity-100"}`} />

			<span
				className={`${cn} duration-200 ${isOpen ? "-rotate-45 -translate-y-1" : "translate-y-0.5"}`}
			/>
		</button>
	);
};

export default BurgerMenu;
