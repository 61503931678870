import React, { FC } from "react";
import { Link } from "gatsby";

import { ImageV2 } from "@src/components/ui";
import { ILocalImage } from "@src/types/page-components.types";

type Props = {
	mobile: ILocalImage;
	desktop: ILocalImage;
};

const Logo: FC<Props> = (props) => {
	const { mobile, desktop } = props;

	return (
		<Link to="/">
			<div className="md:hidden">
				<ImageV2 CMSImage={mobile} alt="logo" />
			</div>
			<div className="hidden md:block">
				<ImageV2 CMSImage={desktop} alt="logo" />
			</div>
		</Link>
	);
};

export default Logo;
