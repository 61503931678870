import React, { FC } from "react";

import { IComponentSectionTitle } from "@src/types/page-components.types";

type Props = {
	content: IComponentSectionTitle;
	className?: string;
};

const SectionTitle: FC<Props> = (props) => {
	const { content, ...rest } = props;

	const { title, htmlTag } = content;

	switch (htmlTag) {
		case "h1":
			return <h1 {...rest}>{title}</h1>;
		case "h2":
			return <h2 {...rest}>{title}</h2>;
		case "h3":
			return <h3 {...rest}>{`${title}`}</h3>;
		case "h4":
			return <h4 {...rest}>{title}</h4>;
		case "h5":
			return <h5 {...rest}>{title}</h5>;
		case "h6":
			return <h6 {...rest}>{title}</h6>;
	}

	return <p {...rest}>{title}</p>;
};

export default SectionTitle;
