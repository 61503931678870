import React, { FC } from "react";

import Link from "../link";
import { IComponentLink, IWithKey } from "@src/types/page-components.types";

type Props = {
	content: (IComponentLink & IWithKey)[] | [];
};

const ProjectHashtags: FC<Props> = (props) => {
	const { content } = props;

	if (content.length === 0) return null;

	return (
		<ul className="flex flex-wrap gap-x-6 gap-y-2">
			{content.map(({ _key, target, label }) => (
				<li key={_key}>
					<Link target={target}>
						<p className={`text-gray-02 ${target !== null ? "hover:underline" : ""}`}>{label}</p>
					</Link>
				</li>
			))}
		</ul>
	);
};

export default ProjectHashtags;
