import React, { FC } from "react";

import Link from "../link";
import { IComponentLink } from "@src/types/page-components.types";
import { ReactComponent as WhiteArrowRight } from "@assets/icons/white-arrow-right.svg";

type Props = {
	content: {
		heading: string;
		link: IComponentLink;
	};
};

const HeadingBlurLink: FC<Props> = (props) => {
	const { content } = props;
	const { heading, link } = content;

	return (
		<Link
			target={link.target}
			className="group block pt-[1px] lg:p-[1px] lg:mb-6 lg:mx-4 lg:rounded-full bg-gradient-to-t from-transparent to-white/20"
		>
			<div className="lg:rounded-full backdrop-blur-md">
				<div className="lg:flex lg:items-center lg:justify-between pt-6 pb-7 px-4 lg:py-8 lg:px-10 lg:rounded-full bg-white/5 transition-colors duration-200 group-hover:bg-white/15">
					<p className="max-w-6xl mb-6 lg:mb-0 lg:mr-8 text-[0.9375rem] font-light leading-6 tracking-[-0.5%] text-white">
						{heading}
					</p>
					<div className="flex items-center">
						<p className="mr-4 font-medium text-white whitespace-nowrap">{link.label}</p>
						<WhiteArrowRight />
					</div>
				</div>
			</div>
		</Link>
	);
};

export default HeadingBlurLink;
