import React, { FC } from "react";

import { IComponentAddress, IWithKey } from "@src/types/page-components.types";

type Props = {
	content: (IComponentAddress & IWithKey)[];
	className?: string;
};

const Address: FC<Props> = (props) => {
	const { content, className } = props;

	return (
		<address className={className}>
			<ul>
				{content.map((address) => {
					const { _key, street, location, href } = address;

					return (
						<li
							key={_key}
							className="mb-4 lg:mb-6 last:mb-0 text-[0.8125rem] leading-5 tracking-[-0.5%] font-normal not-italic text-[#5B5F66]"
						>
							<a href={href} target="_blank" className="inline-block hover:underline">
								{street} <br /> {location}
							</a>
						</li>
					);
				})}
			</ul>
		</address>
	);
};

export default Address;
