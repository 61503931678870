import React, { FC } from "react";

import { Link, Image } from "@src/components/ui";
import { IComponentNavigationPageLink } from "@src/types/page-components.types";
import { ReactComponent as GreyAccordionArrowDown } from "@assets/icons/grey-accordion-arrow-down.svg";
import { ReactComponent as BlackArrowRightSmall } from "@assets/icons/black-arrow-right-small.svg";

type Props = {
	content: IComponentNavigationPageLink[];
};

const PageLinks: FC<Props> = (props) => {
	const { content } = props;

	return (
		<ul className="z-50 flex items-center">
			{content.map((pageLink, index) => {
				const { _key, link, subLinks } = pageLink;

				const hasSubLinks = Boolean(subLinks.length);

				return (
					<li
						key={_key}
						className={`${hasSubLinks ? "mr-[26px]" : "mr-12"} last:mr-0 md:py-7 group relative`}
					>
						<div className="flex items-center justify-between">
							<Link target={link.target}>
								<p
									className={`${link.target !== null ? "hover:underline" : ""} font-normal text-[0.9374rem] leading-6 tracking-[-0.5%]`}
								>
									{link.label}
								</p>
							</Link>
							{hasSubLinks && (
								<div className="transition-transform duration-200 group-hover:-rotate-180 ml-[6px] cursor-pointer">
									<span className="[&>svg]:transition-color [&>svg]:duration-200 [&>svg]:fill-[#C3C4D8] group-hover:[&>svg]:fill-black">
										<GreyAccordionArrowDown />
									</span>
								</div>
							)}
						</div>
						{hasSubLinks && (
							<ul
								className={`grid ${subLinks.length > 4 ? "grid-cols-2" : "grid-cols-1"} w-max absolute ${index === 0 ? "top-16 left-[-16px]" : "top-16 left-[-34px] lg:left-auto lg:right-[-16px]"} p-2 bg-white rounded-lg drop-shadow-xl transition-all duration-100 opacity-0 invisible pointer-events-none group-hover:opacity-100 group-hover:visible group-hover:pointer-events-auto`}
							>
								{subLinks.map((subLink) => {
									const { _key, label, target, icon } = subLink;

									return (
										<li key={_key} className="w-[15.5rem]">
											<Link target={target}>
												<div className="group/subLink flex items-center w-full py-3 pl-2 pr-4 rounded-md transition-all duration-100 hover:bg-gray-03">
													{icon && <Image CMSImage={icon} alt={label} className="mr-3" />}
													<p className="mr-2 text-[0.8125rem] leading-6 tracking-[-0.5%] font-light">
														{label}
													</p>
													<span className="inline-block ml-auto opacity-0 translate-x-[-8px] transition-all duration-100 group-hover/subLink:opacity-100 group-hover/subLink:translate-x-0">
														<BlackArrowRightSmall />
													</span>
												</div>
											</Link>
										</li>
									);
								})}
							</ul>
						)}
					</li>
				);
			})}
		</ul>
	);
};

export default PageLinks;
