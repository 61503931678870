import React, { FC, ReactNode } from "react";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";

import { createPagePath } from "@src/utils/create-pages.utils";
import { ISEO } from "@src/types/page-components.types";

type TGatsbyLinkProps = Omit<GatsbyLinkProps<Record<string, never>>, "to" | "ref" | "target">;

type Props = {
	children: ReactNode;
	target: ISEO | null;
} & TGatsbyLinkProps;

const Link: FC<Props> = (props) => {
	const { children, target, ...rest } = props;

	if (target === null) return <span>{children}</span>;

	const to = createPagePath(target);

	return (
		<GatsbyLink {...rest} to={to}>
			{children}
		</GatsbyLink>
	);
};

export default Link;
