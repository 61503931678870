import React, { FC, ReactNode } from "react";

import { CookieBanner, Footer, Navigation } from "./components";

type Props = {
	children: ReactNode;
};

const Layout: FC<Props> = (props) => {
	const { children } = props;

	return (
		<div className="flex flex-col h-max min-h-screen selection:bg-primary selection:text-white">
			<Navigation />
			<main>{children}</main>
			<Footer />
			<CookieBanner />
		</div>
	);
};

export default Layout;
