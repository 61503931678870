import React, { FC } from "react";
import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";

import { TMarksColorProps, TMarksLinkProps } from "./block-content.types";
import { IComponentRawTextEditor } from "@src/types/page-components.types";

type Props = {
	content: IComponentRawTextEditor[];
};

const builder = imageUrlBuilder({
	projectId: process.env.GATSBY_SANITY_PROJECT_ID as string,
	dataset: process.env.GATSBY_SANITY_DATASET as string,
});

const components = {
	block: {
		normal: ({ children }: any) => {
			if (typeof children === "object" && children[0] === "") return <br />;

			return <p>{children}</p>;
		},
		blockquote: ({ children }: any) => {
			return (
				<p className="pt-8 pb-10 px-4 lg:p-10 lg:pb-12 text-h3-mobile md:text-h3-desktop text-gray-01 bg-gray-03">
					{children}
				</p>
			);
		},
		h1: ({ children }: any) => <h1 className="mt-14 mb-7 md:mt-12 md:mb-6">{children}</h1>,
		h2: ({ children }: any) => <h2 className="mt-14 mb-7 md:mt-12 md:mb-6">{children}</h2>,
		h3: ({ children }: any) => <h3 className="mt-14 mb-7 md:mt-12 md:mb-6">{children}</h3>,
		h4: ({ children }: any) => <h4 className="mt-14 mb-7 md:mt-12 md:mb-6">{children}</h4>,
		h5: ({ children }: any) => <h5 className="mt-14 mb-7 md:mt-12 md:mb-6">{children}</h5>,
		h6: ({ children }: any) => <h6 className="mt-14 mb-7 md:mt-12 md:mb-6">{children}</h6>,
	},
	list: {
		bullet: ({ children }: any) => <ul className="my-4">{children}</ul>,
		number: ({ children }: any) => <ol className="my-4">{children}</ol>,
	},
	listItem: {
		bullet: ({ children }: any) => (
			<li className="diamond-marker mb-2 last:mb-0 text-body-mobile md:text-body-desktop">
				{children}
			</li>
		),
		number: ({ children }: any) => (
			<li className="text-body-mobile md:text-body-desktop">{children}</li>
		),
	},
	marks: {
		color: (props: TMarksColorProps) => {
			const { children, value } = props;
			const color = value?.hex;

			if (color) return <span style={{ color }}>{children}</span>;

			return children;
		},
		link: (props: TMarksLinkProps) => {
			const { children, value } = props;

			return (
				<a href={value?.href} target="_blank" className="text-inherit underline">
					{children}
				</a>
			);
		},
	},
	types: {
		image: (props: any) => {
			const asset = props?.value?.asset as any;
			if (!asset) return null;

			const { width, height, filename } = asset;

			const alt = filename.split(".")[0];
			const src = builder.image(asset).width(width).height(height).url();

			return (
				<div className="my-2">
					<img src={src} width={width} alt={alt} />
					<p className="pt-3 text-[0.75rem] leading-4 text-center">{alt}</p>
				</div>
			);
		},
	},
};

const BlockContent: FC<Props> = (props) => {
	const { content } = props;

	return <PortableText value={content} components={components} />;
};

export default BlockContent;
