import React, { FC } from "react";

import { ImageV2, Link } from "@src/components/ui";
import { IDialog } from "../../cookie-banner.types";
import { ReactComponent as BlackCloseIcon } from "@assets/icons/black-close.svg";

type Props = {
	content: IDialog;
	isOpen: boolean;
	onClose: () => void;
	onDeny: () => void;
	onAccept: () => void;
};

const Dialog: FC<Props> = (props) => {
	const { content, isOpen, onClose, onDeny, onAccept } = props;

	const { logo, title, subtitle, link, denyLabel, acceptLabel } = content;

	if (!isOpen) return null;

	return (
		<div className="fixed w-full h-full bg-black/20">
			<div className="z-50 fixed top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 w-[calc(100%-32px)] lg:w-auto bg-white border-[0.5px] border-[#D6D7E6] rounded-3xl">
				<div className="flex justify-between items-center p-6">
					<ImageV2 CMSImage={logo} alt="logo" />
					<button aria-label="Close" onClick={onClose}>
						<BlackCloseIcon />
					</button>
				</div>
				<div className="mb-4 p-4">
					<p className="mb-2 text-h5-mobile md:text-h5-desktop text-gray-01">{title}</p>
					<p className="mb-4">
						{subtitle}{" "}
						<Link target={link.target}>
							<p className="underline">{link.label}</p>
						</Link>
					</p>
				</div>
				<div className="flex justify-end w-full p-4 border-t-[0.5px] border-[#D6D7E6]">
					<button
						onClick={onDeny}
						className="mr-4 py-3 px-8 transition-colors duration-200 bg-primary hover:bg-[#000096] rounded-full text-[0.8125rem] tracking-[-0.5%] leading-4 text-white text-center font-medium"
					>
						{denyLabel}
					</button>
					<button
						onClick={onAccept}
						className="py-3 px-8 transition-colors duration-200 bg-primary hover:bg-[#000096] rounded-full text-[0.8125rem] tracking-[-0.5%] leading-4 text-white text-center font-medium"
					>
						{acceptLabel}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Dialog;
