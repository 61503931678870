import React, { FC } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { SnackbarVariant } from "@src/enums/component.enums";
import { DEFAULT_LOCALE } from "@src/constants/i18n.constants";
import { ISnackbarData } from "./snackbar.types";
import { ReactComponent as SnackbarSuccess } from "@assets/icons/snackbar-success.svg";
import { ReactComponent as SnackbarError } from "@assets/icons/snackbar-error.svg";
import { ReactComponent as SnackbarClose } from "@assets/icons/snackbar-close.svg";

type Props = {
	open: boolean;
	variant: SnackbarVariant;
	onClose: () => void;
};

const RenderIcon = (variant: SnackbarVariant) => {
	return {
		[SnackbarVariant.SUCCESS]: <SnackbarSuccess />,
		[SnackbarVariant.ERROR]: <SnackbarError />,
	}[variant];
};

const bgClasses = {
	[SnackbarVariant.SUCCESS]: "bg-[#00C756]",
	[SnackbarVariant.ERROR]: "bg-[#C70038]",
};

const Snackbar: FC<Props> = (props) => {
	const { open, variant, onClose } = props;

	const { allSanitySnackbar } = useStaticQuery(graphql`
		{
			allSanitySnackbar {
				nodes {
					language
					successText
					errorText
				}
			}
		}
	`) as ISnackbarData;

	const snackbar = allSanitySnackbar.nodes.find((node) => node.language === DEFAULT_LOCALE);

	if (!snackbar) return null;

	const { successText, errorText } = snackbar;

	const text = {
		[SnackbarVariant.SUCCESS]: successText,
		[SnackbarVariant.ERROR]: errorText,
	};

	return (
		<aside
			className={`${open ? "translate-y-0" : "-translate-y-[calc(100%+40px)]"} z-[100] fixed top-10 left-2/4 -translate-x-2/4 flex justify-between md:justify-normal items-center w-[calc(100%-16px)] md:w-auto py-3 px-4 md:py-6 md:pr-6 md:pl-8 rounded-lg transition-transform duration-200 ${bgClasses[variant]}`}
		>
			<span className="hidden md:block">{RenderIcon(variant)}</span>
			<p className="mr-5 md:ml-5 md:font-bold text-white">{text[variant]}</p>
			<button aria-label="Close" onClick={onClose}>
				<SnackbarClose />
			</button>
		</aside>
	);
};

export default Snackbar;
