import React, { FC } from "react";
import { GatsbyLinkProps, Link } from "gatsby";

import Image from "../image";
import { createPagePath } from "@src/utils/create-pages.utils";
import { IComponentCallToAction } from "@src/types/page-components.types";

type TGatsbyLinkProps = Omit<GatsbyLinkProps<Record<string, never>>, "to" | "ref">;

type Props = {
	CMSCallToAction: IComponentCallToAction;
} & TGatsbyLinkProps;

const CallToAction: FC<Props> = (props) => {
	const { CMSCallToAction, ...rest } = props;
	const { startIcon, label, endIcon, target } = CMSCallToAction;

	const to = createPagePath(target);

	return (
		<Link {...rest} to={to}>
			{startIcon && <Image CMSImage={startIcon} alt="Call to action start icon" />}
			<span>{label}</span>
			{endIcon && <Image CMSImage={endIcon} alt="Call to action end icon" />}
		</Link>
	);
};

export default CallToAction;
