import React, { FC } from "react";

import { ImageV2 } from "@src/components/ui";
import { IBanner } from "../../cookie-banner.types";

type Props = {
	content: IBanner;
	isVisible: boolean;
	onOpenDialog: () => void;
	onAccept: () => void;
};

const Banner: FC<Props> = (props) => {
	const { content, isVisible, onOpenDialog, onAccept } = props;

	const { icon, text, openDialogLabel, acceptLabel } = content;

	if (!isVisible) return null;

	return (
		<div className="z-50 fixed bottom-0 right-0 flex flex-col items-center md:flex-row w-[calc(100%-32px)] md:w-auto mx-4 mb-6 md:mx-14 md:mb-10 p-5 md:p-2 md:pl-8 bg-white border-[0.5px] border-[#D6D7E6] rounded-3xl md:rounded-full">
			<ImageV2 CMSImage={icon} alt="cookie icon" />
			<div className="flex flex-col md:items-start mt-3 mb-8 md:mt-0 md:mb-0 md:mr-12 md:ml-5">
				<p className="mb-1">{text}</p>
				<button
					onClick={onOpenDialog}
					className="text-[0.8125rem] leading-4 tracking-[-0.5%] underline"
				>
					{openDialogLabel}
				</button>
			</div>
			<button
				onClick={onAccept}
				className="w-full md:w-auto py-3 px-8 md:py-5 transition-colors duration-200 bg-primary hover:bg-[#000096] rounded-full text-[0.8125rem] tracking-[-0.5%] leading-4 text-white text-center font-medium"
			>
				{acceptLabel}
			</button>
		</div>
	);
};

export default Banner;
