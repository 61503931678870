import React, { FC } from "react";
import { Link } from "gatsby";

import { ImageV2 } from "@src/components/ui";
import { ILocalImage } from "@src/types/page-components.types";

type Props = {
	content: ILocalImage;
};

const Logo: FC<Props> = (props) => {
	const { content } = props;

	return (
		<Link to="/" className="inline-block mb-12 lg:mb-[3.375rem]">
			<ImageV2 CMSImage={content} alt="logo" />
		</Link>
	);
};

export default Logo;
